.forecast-chart-container {
  height: 150px;
  margin-left: auto;
  width: 100%;
  background-color: rgb(250, 250, 250);
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
}

.forecast-loading {
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  color: grey;
}

.forecast-scroll-container {
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  flex: 1;
}

.forecast-period {
  width: 226px;
  height: 100%;
  border: 1px solid #dddddd;
  border-right: 5px solid #ebebeb;
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.forecast-period .wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  transition: background-color 300ms;
}

.forecast-period .wrapper:hover {
  background-color: #ebebeb;
}

.forecast-chart-collapse {
  margin: 0;
}

.forecast-chart-container .chart-tooltip {
  display: flex;
  flex-flow: column nowrap;
}

.forecast-chart-container .chart-tooltip .tooltip-title {
  background-color: rgb(236, 236, 236);
  font-weight: 600;
  padding: 5px;
}

.forecast-chart-container .chart-tooltip .tooltip-value {
  padding: 5px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: white;
}

.forecast-chart-container .chart-tooltip .tooltip-value > span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #1f9e67;
  margin-right: 5px;
}

.forecast-chart-container .chart-tooltip .tooltip-estimation {
  background-color: white;
  padding: 5px;
}
.forecast-period .wrapper span.dates-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.forecast-period .wrapper span.dates-display ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.forecast-period .wrapper span.dates-display span {
  padding-left: 20px;
}