.add-event .add-break-btn {
    background-color: white;
    border: 1px solid #1894db;
    border-radius: 4px;
    color: #1894db;
    font-family: inherit;
    padding: 0.5em 1em;
    font-weight: 600;
    outline: none;
    transition: background-color 200ms, color 200ms;
}

.add-event .add-break-btn:hover {
    background-color: #1894db;
    color: white;
}

.add-event .add-break-btn:active {
    background-color: #1b7cb4;
}

.add-event .breaks {
    margin-top: 1.5em;
    margin-bottom: 0;
}

.add-event .breaks > div {
    margin-bottom: 1em;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
}

.add-event .breaks > div::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2.7em;
    background-color: #1894db;
    opacity: 0;
    pointer-events: none;
    border-radius: 4px;
    transform: scaleX(1) scaleY(1);
    transition: opacity 700ms, transform 700ms;
}

.add-event .breaks > div.changed::before {
    transform: scaleX(1.03) scaleY(1.3);
    opacity: 0.1;
}

.add-event .breaks > div > div {
    margin-right: 0.5em;
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
}

.add-event .breaks select {
    outline: none;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    padding: 0.5em;
    flex: 1;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    transition: border-color 200ms;
}

.add-event .breaks select:active {
    border-color: #1894db;
}

.add-event .breaks > div > button {
    background-color: hsl(0, 100%, 90%);
    border: none;
    color: red;
    padding: 0.6em 1em;
    border-radius: 4px;
    outline: none;
    transition: background-color 200ms;
}

.add-event .breaks > div > button:hover {
    background-color: hsl(0, 100%, 80%);
}

.add-event .breaks > div > button:active {
    background-color: hsl(0, 100%, 90%);
}
