.gm-dashboard .date-changer {
  height: 47px;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: border-color 300ms;
  padding: 0 0;
}

.gm-dashboard .date-changer > span {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.gm-dashboard .date-changer:active {
  border-color: #1894db;
}

.dep-card-header {
  line-height: 20px !important;
  font-size: 16.5px;
  font-weight: 600;
  padding: 11px 30px !important;
  margin: 0px !important;
  /* max-height: 30px; */
}

.dep-card-title {
  padding-top: 5px;
  letter-spacing: 0.5px;
}

.dep-card {
  min-height: 130px;
}

.dep-card-body {
  font-weight: 650;
  font-size: 12.7px;
  /* letter-spacing: 0.8px; */
}

.progress-bar-back {
  padding: 0.5px;
  margin: 7px 0px;
  /* border: 1px solid #d6d6d6c2; */
  background-color: #f1f1f1;
  border-radius: 2px;
}

.progress-bar {
  height: 14px;
  display: block;
  background-color: #61c08d !important;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  margin-bottom: 0.5px;
}
.gm-dashboard .react-datepicker-wrapper {
  position: absolute;
  bottom: 50%;
}
.gm-dashboard .react-datepicker-wrapper > .react-datepicker__input-container {
  display: none;
}
