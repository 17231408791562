.add-event {
  display: flex;
  flex-flow: column nowrap;
  max-height: 80vh;
}

.add-event > .wrapper > div {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1.5em;
  position: relative;
}

.add-event > .wrapper {
  flex: 1;
  overflow-y: auto;
  padding-right: 1em;
  margin-top: 10px;
}

.add-event > .header {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.add-event > .header > span {
  font-weight: 700;
  font-size: 1.3em;
}

.add-event > .header > .close-btn {
  background-color: white;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 3px;
  color: grey;
}

.add-event > .header > .close-btn:hover {
  background-color: #1894db;
  border-color: #1894db;
  color: white;
}

.add-event .value-icon {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.add-event .value-icon > .value + i {
  margin-left: 1em;
  color: dimgray;
}

.add-event .value {
  flex: 1;
  font-size: 1.1em;
  padding: 0.5em;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  color: grey;
}

.add-event .label {
  font-size: 0.9em;
  color: dimgrey;
  margin-bottom: 0.5em;
}

.add-event input {
  font-family: inherit;
  font-size: inherit;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  padding: 0.5em;
}

.add-event textarea {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  resize: vertical;
  padding: 0.3em 0.5em;
  font-family: inherit;
  font-size: inherit;
  min-height: 2em;
}

.add-event .wrapper .time-inputs {
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  width: 100%;
}
.add-event .time-inputs .time-error {
  color: red;
  font-size: 1em;
  width: 100%;
  margin-top: 0.5em;
}

.add-event .time-inputs > div:first-child {
  margin-right: 1em;
}

.add-event .time-input {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
}

.add-event .time-input::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2.7em;
  background-color: #1894db;
  opacity: 0;
  pointer-events: none;
  border-radius: 4px;
  transform: scaleX(1) scaleY(1);
  transition: opacity 500ms, transform 500ms;
}

.add-event .time-input.changed::before {
  transform: scaleX(1.05) scaleY(1.3);
  opacity: 0.1;
}

.add-event .time-input input {
  flex: 1;
  max-width: 4em;
  border: 1px solid hsl(0, 0%, 80%);
}

.add-event .time-input input:disabled {
  color: grey;
  background-color: #f2f2f2;
}

.add-event .time-input > input:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.add-event .time-input > input:nth-child(2) {
  border-radius: 0;
  border-right: none;
}

.add-event .time-input > .period {
  flex: 1;
  border: 1px solid #1894db;
  padding: 0.5em;
  font-weight: 700;
  user-select: none;
  color: #1894db;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.add-event .time-input > .period.selected {
  background-color: #1894db;
  border-color: #1894db;
  color: white;
}

.add-event .time-input > .period:not(.selected).disabled {
  background-color: hsl(219, 44%, 97%);
  border-color: hsl(219, 44%, 85%);
  color: hsl(219, 54%, 79%);
}

.add-event .time-input > .period.selected.disabled {
  background-color: #439ed3;
  border-color: #439ed3;
  color: hsl(219, 54%, 79%);
}

.add-event .time-input > .period:not(.selected):not(.disabled):hover {
  cursor: pointer;
  background-color: rgb(196, 218, 236);
}

.add-event .time-input > .period:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.add-event .buttons {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 1.5em;
}

.add-event .buttons > button {
  flex: 1;
  /* box-shadow: none; */
}

.add-event .buttons > button + button {
  margin-left: 1em;
}

.add-event .break-error {
  color: red;
  font-size: 1em;
}
.add-event > .wrapper > div.hidden {
  display: none;
}