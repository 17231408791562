/* datepicker */
.forecasting-date-picker {
  background-color: white;
  display: flex;
  flex-flow: row nowrap;
  height: 35px;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  position: relative;
  margin: 0 10px;
}

.forecasting-date-picker > div:first-of-type {
  position: absolute;
  left: 0;
  width: 0;
}

.forecasting-date-picker > .date-changer:hover {
  background-color: #e8ecf0;
}

.date-changer {
  cursor: pointer;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 10px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  transition: opacity 200ms;
  user-select: none;
}

.forecasting-date-picker > .date-changer > i {
  font-size: 0.6em;
}

.forecasting-date-picker > .actions {
  display: flex;
  height: 100%;
  flex-flow: row nowrap;
  align-items: stretch;
  border-left: 1px solid #e7e7e7;
}

.forecasting-date-picker > .actions > select {
  background-color: white;
  border: none;
  border: 1px solid #e7e7e7;
  border-bottom: none;
  border-top: none;
  height: 100%;
  padding: 0 5px;
  outline: none;
  cursor: pointer;
}

.forecasting-date-picker > .actions > select:hover {
  background-color: #e8ecf0;
}
.forecasting-date-picker > .actions > button:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.forecasting-date-picker > .actions > button {
  background-color: white;
  border: none;
  font-size: 0.6em;
  padding: 0 15px;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  outline: none;
}

.forecasting-date-picker > .actions > button:hover {
  background-color: #e8ecf0;
}

.forecasting-date-picker > .actions > button:active {
  background-color: #eaf1f8;
}

.forecasting-date-picker .DateRangePickerInput {
  border: none;
}

.forecasting-bar {
  margin-top: 110px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 10px 5%;
}

.forecasting-bar > div:first-of-type {
  display: flex;
  flex-flow: row nowrap;
}

.forecasting-bar > div:first-of-type select {
  height: 35px !important;
}

.forecasting-bar > div:first-of-type select:first-child {
  margin-right: 10px;
}

@media (max-width: 992px) {
  .forecasting-bar {
    flex-flow: column nowrap;
    margin-top: 60px;
  }

  .forecasting-bar > div:first-of-type {
    margin-bottom: 10px;
  }
}

/* rest */
.react-datepicker-popper {
  z-index: 100;
}

.SingleDatePickerInput {
  border: none;
  background-color: transparent;
  width: 0;
}

.SingleDatePickerInput > .DateInput {
  pointer-events: none;
  opacity: 0;
  width: 0;
}

.forecasting-date-picker
  .react-datepicker-wrapper
  > .react-datepicker__input-container {
  display: none;
}

.forecasting-date-picker select {
  min-width: 100px;
}

.forecasting-date-picker > div:first-of-type {
  width: 5px;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: #1894db;
  border: 1px solid #1894db;
  color: white;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  color: #1894db;
  background-color: #d1e6f1;
  border: 1px solid #1894db;
}

.CalendarDay__selected_span{
  color: #1894db;
  background-color: #d1e6f1;
  border: 1px solid #bbced8;
}

.CalendarDay__selected_span:hover{
  background-color: #8ec1df;
  color: white;
  border: 1px solid #bbced8;
}

.chart-wrapper {
  margin-top: 2em;
}

.widgets {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.widget {
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgb(236, 236, 236);
  outline: none;
  width: 32%;
  height: 80px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  /* overflow: hidden; */
}

.widget::before {
  content: "";
  height: 0;
  width: 2px;
  position: absolute;
  left: 0px;
  transition: height 1s;
  border-radius: 5px;
}

.widget.loaded::before {
  height: 50%;
}

.widget.employees::before {
  background-color: #1894db;
}

.widget.schedules::before {
  background-color: #61c08d;
}

.widget.classes::before {
  background-color: #eb3b7f;
}

.widget .card-body {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
}

.widget .card-body > span {
  font-size: 1.3em;
}

.widget .card-body > div {
  font-size: 1.1em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 0.5em;
}

.widget .card-body > div > span:last-child {
  font-size: 2em;
  line-height: 1.5em;
  font-weight: 500;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  z-index: 4;
  color: white;
}

.widget .card-body > div > span:first-child {
  position: absolute;
  bottom: 0;
  width: calc(100% + 1.2em);
  height: 0;
  border-radius: 4px;
  transition: height 1.2s;
}

.widget.employees .card-body > div > span:first-child {
  background-color: #027ade;
}

.widget.schedules .card-body > div > span:first-child {
  background-color: #61c08d;
}
.widget.classes .card-body > div > span:first-child {
  background-color: #eb3b7f;
}
.widget.loaded .card-body > div > span:first-child {
  height: 100%;
}

@media (max-width: 992px) {
  .widgets {
    flex-flow: column nowrap;
  }

  .widget {
    width: 100%;
    margin-bottom: 10px;
  }
}
