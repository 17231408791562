.user-avatar {
  background-color: #bec5ca;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #f3f3f3;
  margin-left: 300px;
  margin: 0;
  position: relative;
}

.user-avatar.group {
  border: none;
  background-color: transparent;
}

.user-avatar.group .badge {
  position: absolute;
  background-color: #1894db;
  color: white;
  right: 0;
  bottom: 0;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  padding: 0;
}

.user-avatar > .user-avatar {
  position: absolute;
}

.user-avatar > .user-avatar:nth-child(1) {
  right: 0;
  top: 0;
}

.user-avatar > .user-avatar:nth-child(2) {
  left: 0;
  bottom: 0;
}

.user-avatar > div {
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  transform: translateY(1px);
}
