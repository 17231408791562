.text-red {
  color: #b31919;
}
.text-green {
  color: #22bd22;
}

.report-switch {
  /* margin-top: 8px; */
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.report-switch > button {
  border: none;
  border-left: 1px solid rgb(224, 224, 224);
  border-right: 1px solid rgb(224, 224, 224);
  border-radius: 0 !important;
  background-color: white;
  flex: 1;
  padding: 10px 0;
  outline: none !important;
  text-transform: capitalize;
  transition: background-color 200ms, border-color 200ms;
}

.report-switch > button:first-of-type {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-top: 1px solid rgb(224, 224, 224);
}

.report-switch > button:last-of-type {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-bottom: 1px solid rgb(224, 224, 224);
}

.report-switch > button:hover {
  background-color: #e7e7e7;
}

.report-switch > button:active {
  background-color: #dddddd;
}

.report-switch > button.active {
  background-color: #1894db;
  border-color: #1894db;
  color: white;
}

#report-table td,
#report-table th {
  padding: 10px 15px;
}

#report-table td > div.detailed-changes {
  width: 100%;
}

#report-table td > div.detailed-changes > div:hover {
  background-color: white;
}

#report-table td > div.detailed-changes > div {
  border: 1px solid #eee;
  border-radius: 3px;
  margin-bottom: 5px;
  padding: 5px 8px;
}

#report-table td > div.detailed-changes .title {
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 3px;
  font-size: 0.9em;
  color: #727272;
}

#report-table td > div.detailed-changes .data {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

#report-table td > div.detailed-changes .data > div {
  flex: 1;
  /* width: 50%; */
  color: #3c7e3c;
}

#report-table td > div.detailed-changes .data > div:first-of-type {
  text-decoration: line-through;
  text-decoration-color: #cf9595;
  color: rgb(155, 42, 42);
}

#report-table td > div.detailed-changes .data > i {
  color: #a5a5a5;
  font-size: 0.8em;
  margin: 0 10px;
}

#report-table td > div.detailed-changes-event-id {
  color: #4e44ce;
  font-size: 0.8em;
  margin: 0 0 5px 0;
}

@media (max-width: 767px) {
  .report-switch {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}
