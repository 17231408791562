.event-color-red {
    background-color: #fcdddc;
    border-color: #ec3633;
}
.event-color-red {
    color: #cd1613;
}
.event-color-red:hover {
    background-color: #f9bab9;
}

.event-color-red.selected {
    background-color: #ef5350;
}

.event-color-red.selected {
    color: #fff;
}
.event-color-red.selected:hover {
    background-color: #ec3633;
}

.event-color-pink {
    background-color: #fbd9e4;
    border-color: #e92567;
}
.event-color-pink {
    color: #bf134d;
}
.event-color-pink:hover {
    background-color: #f7b3ca;
}

.event-color-pink.selected {
    background-color: #ec407a;
}

.event-color-pink.selected {
    color: #fff;
}
.event-color-pink.selected:hover {
    background-color: #e92567;
}

.event-color-purple {
    background-color: #eedaf2;
    border-color: #9b3eab;
}
.event-color-purple {
    color: #793085;
}
.event-color-purple:hover {
    background-color: #ddb5e4;
}

.event-color-purple.selected {
    background-color: #ab47bc;
}

.event-color-purple.selected {
    color: #fff;
}
.event-color-purple.selected:hover {
    background-color: #9b3eab;
}

.event-color-violet {
    background-color: #e5ddf3;
    border-color: #6e43ba;
}
.event-color-violet {
    color: #563490;
}
.event-color-violet:hover {
    background-color: #cbbce7;
}

.event-color-violet.selected {
    background-color: #7e57c2;
}

.event-color-violet.selected {
    color: #fff;
}
.event-color-violet.selected:hover {
    background-color: #6e43ba;
}

.event-color-indigo {
    background-color: #dee1f2;
    border-color: #4858b8;
}
.event-color-indigo {
    color: #37458f;
}
.event-color-indigo:hover {
    background-color: #bec4e6;
}

.event-color-indigo.selected {
    background-color: #5c6bc0;
}

.event-color-indigo.selected {
    color: #fff;
}
.event-color-indigo.selected:hover {
    background-color: #4858b8;
}

.event-color-blue {
    background-color: #d9edfd;
    border-color: #2497f3;
}
.event-color-blue {
    color: #0b77cf;
}
.event-color-blue:hover {
    background-color: #b3dbfb;
}

.event-color-blue.selected {
    background-color: #42a5f5;
}

.event-color-blue.selected {
    color: #fff;
}
.event-color-blue.selected:hover {
    background-color: #2497f3;
}

.event-color-cyan {
    background-color: #d4f4f8;
    border-color: #22b3c5;
}
.event-color-cyan {
    color: #1a8b99;
}
.event-color-cyan:hover {
    background-color: #a8e8f0;
}

.event-color-cyan.selected {
    background-color: #26c6da;
}

.event-color-cyan.selected {
    color: #fff;
}
.event-color-cyan.selected:hover {
    background-color: #22b3c5;
}

.event-color-teal {
    background-color: #cdf4f0;
    border-color: #22958b;
}
.event-color-teal {
    color: #1b746c;
}
.event-color-teal:hover {
    background-color: #9be8e1;
}

.event-color-teal.selected {
    background-color: #26a69a;
}

.event-color-teal.selected {
    color: #fff;
}
.event-color-teal.selected:hover {
    background-color: #22958b;
}

.event-color-green {
    background-color: #e0f1e1;
    border-color: #52b257;
}
.event-color-green {
    color: #3e8c42;
}
.event-color-green:hover {
    background-color: #c2e4c3;
}

.event-color-green.selected {
    background-color: #66bb6a;
}

.event-color-green.selected {
    color: #fff;
}
.event-color-green.selected:hover {
    background-color: #52b257;
}

.event-color-lime {
    background-color: #f6f9dd;
    border-color: #cddc3d;
}
.event-color-lime {
    color: #abb921;
}
.event-color-lime:hover {
    background-color: #eef3bc;
}

.event-color-lime.selected {
    background-color: #d4e157;
}

.event-color-lime.selected {
    color: #fff;
}
.event-color-lime.selected:hover {
    background-color: #cddc3d;
}
.event-color-yellow {
    background-color: #fff7d7;
    border-color: #fdd217;
}
.event-color-yellow {
    color: #d4ad02;
}
.event-color-yellow:hover {
    background-color: #feefae;
}

.event-color-yellow.selected {
    background-color: #fdd835;
}

.event-color-yellow.selected {
    color: #fff;
}
.event-color-yellow.selected:hover {
    background-color: #fdd217;
}

.event-color-orange {
    background-color: #ffedd4;
    border-color: #ff9b09;
}
.event-color-orange {
    color: #cd7a00;
}
.event-color-orange:hover {
    background-color: #ffdca8;
}

.event-color-orange.selected {
    background-color: #ffa726;
}

.event-color-orange.selected {
    color: #fff;
}
.event-color-orange.selected:hover {
    background-color: #ff9b09;
}

.event-color-gray {
    background-color: #ececec;
    border-color: #909090;
}
.event-color-gray {
    color: #707070;
}
.event-color-gray:hover {
    background-color: #d9d9d9;
}

.event-color-gray.selected {
    background-color: #a0a0a0;
}

.event-color-gray.selected {
    color: #fff;
}
.event-color-gray.selected:hover {
    background-color: #909090;
}

.event-color-gray-lines {
    background-image: linear-gradient(
        -45deg,
        rgba(0, 0, 0, 0) 46%,
        #909090 30%,
        #909090 30%,
        rgba(0, 0, 0, 0) 52%
    );
    background-size  : 6px 6px !important;
    background-color: transparent;
    border-color: #909090;
}
.event-color-gray-lines:hover,
.event-color-gray-lines.selected {
    background-color: #828282;
    background-image: none;
    color: white
}

.event-color-gray-lines.selected:hover {
    background-color: #828282;
}

.event-color-red-lines {
    color: #b10f0f;
    font-weight: 500;
}
.event-color-red-lines {
    border-color: #b10f0f;
    background-image: linear-gradient(
        -45deg,
        rgba(0, 0, 0, 0) 46%,
        #e03218 30%,
        #e03218 30%,
        rgba(0, 0, 0, 0) 52%
    );
    background-size: 6px 6px !important;
    background-color: transparent;
}
.event-color-red-lines:hover,
.event-color-red-lines.selected {
    background-color: #e88f8f;
    background-image: none;
    color: white
}

.event-color-red-lines.selected:hover {
    background-color: #e88f8f;
}

.event-color-released {
    color: #0681ec;
    font-weight: 500;
    border-color: #01BAEF;
    background-image: linear-gradient(
        -45deg,
        transparent 46%,
        rgba(0, 144, 185, 0.85) 0,
        rgba(0, 144, 185, 0.85) 30%,
        transparent 52%
    );
    background-size: 6px 6px !important;
    background-color: transparent;
}
.event-color-released:hover,
.event-color-released.selected {
    background-color: #01BAEF;
    background-image: none;
    color: white
}
.event-color-released.selected:hover {
    background-color: #01BAEF;
}

/* Zones Colors */

/* Zone 1 */
span.zone.color_zone_1{
    background-color: #5766b5;
}
/* .event-color-color_zone_1 {
    color: #14288e;
    font-weight: 500;
    border-color: #5766b5;
    background-color: #c3c9ec;
}
.event-color-color_zone_1:hover,
.event-color-color_zone_1.selected {
    background-color: #5766b5;
    color: white
}
.event-color-color_zone_1.selected:hover {
    background-color: #5766b5;
} */

/* Zone 2 */
span.zone.color_zone_2{
    background-color: #1db4a5;
}
/* .event-color-color_zone_2 {
    color: #136e65;
    font-weight: 500;
    border-color: #1db4a5;
    background-color: #3ae4d3;
}
.event-color-color_zone_2:hover,
.event-color-color_zone_2.selected {
    background-color: #1db4a5;
    color: white
}
.event-color-color_zone_2.selected:hover {
    background-color: #1db4a5;
} */

/* Zone 3 */
span.zone.color_zone_3{
    background-color: #cf8e52;
}
/* .event-color-color_zone_3 {
    color: #805834;
    font-weight: 500;
    border-color: #cf8e52;
    background-color: #f3bb86;
}
.event-color-color_zone_3:hover,
.event-color-color_zone_3.selected {
    background-color: #cf8e52;
    color: white
}
.event-color-color_zone_3.selected:hover {
    background-color: #cf8e52;
} */

/* Zone 4 */
span.zone.color_zone_4{
    background-color: #896cbd;
}
/* .event-color-color_zone_4 {
    color: #683fb6;
    font-weight: 500;
    border-color: #896cbd;
    background-color: #d6b0f5;
}
.event-color-color_zone_4:hover,
.event-color-color_zone_4.selected {
    background-color: #896cbd;
    color: white
}
.event-color-color_zone_4.selected:hover {
    background-color: #896cbd;
} */

/* Zone 5 */
span.zone.color_zone_5{
    background-color: #9e8e84;
}
/* .event-color-color_zone_5 {
    color: #3f3f3f;
    font-weight: 500;
    border-color: #9e8e84;
    background-color: #d3c4ba;
}
.event-color-color_zone_5:hover,
.event-color-color_zone_5.selected {
    background-color: #9e8e84;
    color: white
}
.event-color-color_zone_5.selected:hover {
    background-color: #9e8e84;
} */

/* Zone 6 */
span.zone.color_zone_6{
    background-color: #e7786e;
}
/* .event-color-color_zone_6 {
    color: #d14c40;
    font-weight: 500;
    border-color: #e7786e;
    background-color: #f3b9b4;
}
.event-color-color_zone_6:hover,
.event-color-color_zone_6.selected {
    background-color: #e7786e;
    color: white
}
.event-color-color_zone_6.selected:hover {
    background-color: #e7786e;
} */

/* Zone 7 */
span.zone.color_zone_7{
    background-color: #53e4a3;
}
/* .event-color-color_zone_7 {
    color: #013a20;
    font-weight: 500;
    border-color: #1fa568;
    background-color: #53e4a3;
}
.event-color-color_zone_7:hover,
.event-color-color_zone_7.selected {
    background-color: #1fa568;
    color: white
}
.event-color-color_zone_7.selected:hover {
    background-color: #1fa568;
} */

/* Zone 8 */
span.zone.color_zone_8{
    background-color: #e4d873;
}
/* .event-color-color_zone_8 {
    color: #837a2c;
    font-weight: 500;
    border-color: #bbac29;
    background-color: #e4d873;
}
.event-color-color_zone_8:hover,
.event-color-color_zone_8.selected {
    background-color: #bbac29;
    color: white
}
.event-color-color_zone_8.selected:hover {
    background-color: #bbac29;
} */

/* Zone 9 */
span.zone.color_zone_9{
    background-color: #8baf8f;
}
/* .event-color-color_zone_9 {
    color: #4d664f;
    font-weight: 500;
    border-color: #8baf8f;
    background-color: #b2c7b4;
}
.event-color-color_zone_9:hover,
.event-color-color_zone_9.selected {
    background-color: #8baf8f;
    color: white
}
.event-color-color_zone_9.selected:hover {
    background-color: #8baf8f;
} */

/* Zone 10 */
span.zone.color_zone_10{
    background-color: #e980ae;
}
/* .event-color-color_zone_10 {
    color: #832950;
    font-weight: 500;
    border-color: #e980ae;
    background-color: #f7a6c9;
}
.event-color-color_zone_10:hover,
.event-color-color_zone_10.selected {
    background-color: #e980ae;
    color: white
}
.event-color-color_zone_10.selected:hover {
    background-color: #e980ae;
} */

/* Zone 11 */
span.zone.color_zone_11{
    background-color: #5a6cd1;
}
/* .event-color-color_zone_11 {
    color: #353e74;
    font-weight: 500;
    border-color: #5a6cd1;
    background-color: #a8b4f7;
}
.event-color-color_zone_11:hover,
.event-color-color_zone_11.selected {
    background-color: #5a6cd1;
    color: white
}
.event-color-color_zone_11.selected:hover {
    background-color: #5a6cd1;
} */

/* Zone 12 */
span.zone.color_zone_12{
    background-color: #a93636;
}
/* .event-color-color_zone_12 {
    color: #6b673b;
    font-weight: 500;
    border-color: #ccc46d;
    background-color: #e7e3bb;
}
.event-color-color_zone_12:hover,
.event-color-color_zone_12.selected {
    background-color: #ccc46d;
    color: white
}
.event-color-color_zone_12.selected:hover {
    background-color: #ccc46d;
} */
