.emoji-picker {
	position: absolute;
  top: 7px;
  right: 17px;
  width: 35px !important;
}

.emoji-picker > .emoji-trigger {
  color: gray;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
}

.emoji-picker > .emoji-trigger:hover {
  background-color: rgb(233, 232, 232);
}

.emoji-picker > .picker-container {
  position: absolute;
  z-index: 11;
  right: 100%;
	top: -100%;
	transform: translateY(-27%);
}
