.resources {
  width: 120px;
}
.events {
  width: calc(100% - 120px);
}
.pdf-header {
  display: inline-flex;
  width: 100%;
  margin-bottom: 50px;
}
.pdf-date {
  width: calc(100% - 250px);
  padding-left: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #4481d3;
}
.pdf-logo {
  width: 250px;
  float: right;
  padding-right: 50px;
}
.pdf-logo img {
  width: 100%;
}
.pdf-border-top {
  border-top: 1px solid #f0f0f2;
}
.pdf-border-left {
  border-left: 1px solid #f0f0f2;
}
.pdf-border-right {
  border-right: 1px solid #f0f0f2;
}
.pdf-event-col-width {
  width: 4.1666666667%;
}
.pdf-event-last-col-width {
  width: 4.1666666659%;
}
.resource-name {
  width: 120px !important;
}
.black-white * {
  color: black !important;
  background: white !important;
  border-color: black !important;
}
.black-white .b-react-monthly-container .month-tile .month-tile-content .shift {
  border: 3px dashed black !important;
}
.pdf-document .ellipsis {
  white-space: normal;
}
.pdf-document {
  border: 1px solid lightgray;
  border-radius: 4px;
  /* box-shadow: 0 0 10px 2px; */
}
.black-white .b-react-monthly-container .month-tile {
  border: 1px solid black !important;
}
@media print {
  .black-white .b-react-monthly-container .month-tile {
    border: 1px solid black !important;
  }
  .pdf-document {
    display: block;
    page-break-before: always;
  }
  @page { size: "4in 2.5in"}
  span.zone.color_zone_1{
    background-color: #5766b5;
  }
  span.zone.color_zone_2{
      background-color: #1db4a5;
  }
  span.zone.color_zone_3{
      background-color: #cf8e52;
  }
  span.zone.color_zone_4{
      background-color: #896cbd;
  }
  span.zone.color_zone_5{
      background-color: #9e8e84;
  }
  span.zone.color_zone_6{
      background-color: #e7786e;
  }
  span.zone.color_zone_7{
      background-color: #53e4a3;
  }
  span.zone.color_zone_8{
      background-color: #e4d873;
  }
  span.zone.color_zone_9{
      background-color: #8baf8f;
  }
  span.zone.color_zone_10{
      background-color: #e980ae;
  }
  span.zone.color_zone_11{
      background-color: #5a6cd1;
  }
  span.zone.color_zone_12{
      background-color: #ccc46d;
  }
  span.zone{
    font-weight: 500;
    color: white;
    border-radius: 1em;
    padding: 0px 4px;
    letter-spacing: .8px;
    font-size: .9em;
  }
}
.pdf-timestamp {
  color: #97a2ae;
  font-size: 12px;
}

.notes {
  margin-top: 40px;
}

.notes > h4 {
  font-size: 1.3em;
}
