 label.no-margin-bottom{
   margin-bottom: 0px;
 }
.schedule-header {
  background-color: white;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  margin-top: 110px;
}

.schedule-header > div {
  display: flex;
  flex-flow: row nowrap;
}

.b-container .b-has-label label {
  color: rgb(92, 91, 91) !important;
}

@media (max-width: 992px) {
  .schedule-header {
    margin-top: 60px;
  }
}

@media (max-width: 560px) {
  .schedule-header > * {
    flex-flow: column nowrap !important;
  }

  .schedule-header > * {
    width: 100%;
  }

  .schedule-header > * > *:first-child {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .schedule-header .forecasting-date-picker .actions {
    align-items: stretch;
    flex: 1;
  }

  .schedule-header .forecasting-date-picker .actions > select {
    flex: 1;
  }

  .schedule-header .switch-mode {
    width: 100%;
  }

  .schedule-header .switch-mode > button {
    width: 50%;
  }

  .schedule-header .schedule-actions {
    display: flex;
    align-items: stretch;
    width: 100%;
    justify-content: space-between;
  }

  .schedule-header .schedule-actions > button {
    flex: 1;
  }
}

@media (max-width: 1110px) {
  .schedule-header {
    flex-flow: column nowrap;
    align-items: flex-start;
  }

  .schedule-header > *:first-child {
    margin-bottom: 10px !important;
  }

  .schedule-header > * > * {
    margin-left: 0 !important;
  }
}

.schedule-header .switch-mode {
  margin-right: 15px;
}

.schedule-header .switch-mode > button {
  border: 1px solid #e7e7e7;
  padding: 6px 15px;
  border-radius: 4px;
  color: #3a3a3a;
  background-color: white;
  transition: background-color 300ms;
  outline: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.schedule-header .switch-mode > button:hover {
  background-color: #e8ecf0;
}

.schedule-header .switch-mode > button.selected:hover {
  background-color: #1e81bb;
}

.schedule-header .switch-mode > button:first-of-type {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.schedule-header .switch-mode > button:last-of-type {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.schedule-header .switch-mode > button.selected {
  color: white;
  border-color: #1894db;
  background-color: #1894db;
}

.schedule-header .schedule-actions > button {
  box-shadow: none;
  border: 1px solid;
}

.schedule-header .schedule-actions > button:last-child {
  margin-right: 0 !important;
}

.schedule-header .schedule-actions > button:focus {
  border: 1px solid !important;
}

.schedule-header .schedule-actions > button.btn-outline-green {
  background-color: white;
  border-color: #1f9e67;
  color: #1f9e67;
}

.schedule-header .schedule-actions > button.btn-outline-green:hover {
  background-color: #1f9e67;
  color: white;
}

.schedule-header .schedule-actions > button.btn-outline-orange {
  background-color: white;
  border-color: #fe7100;
  color: #fe7100;
}

.schedule-header .schedule-actions > button.btn-outline-orange:hover {
  background-color: #fe7100;
  color: white;
}

.schedule-header .schedule-actions > button.btn-outline-yellow {
  background-color: white;
  border-color: #e4b211;
  color: #e4b211;
}

.schedule-header .schedule-actions > button.btn-outline-yellow:hover {
  background-color: #e4b211;
  color: white;
}

.estimation-subheader {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 10px 15px 0 15px;
}

.estimation-subheader > div {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 1;
}

.estimation-subheader > div {
  margin-bottom: 10px;
}

.estimation-subheader > div > .search-box {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 44px;
  margin-right: 15px;
  position: relative;
}

.estimation-subheader > div input {
  border: 1px solid white;
  transition: border-color 300ms;
  background-color: white;
  border-radius: 4px;
  font-family: inherit;
  font-size: inherit;
  box-shadow: none;
  cursor: initial;
  height: 100%;
  padding: 0 40px 0 15px;
  max-width: 300px;
  min-width: 150px;
}

.estimation-subheader > div > .search-box > input:focus {
  border-color: #1894db;
}

.estimation-subheader > div .search-box > i {
  position: absolute;
  z-index: 8;
  right: 15px;
}

.estimation-subheader > div > select {
  min-width: 100px;
  max-width: 300px;
}

.estimation {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
}

.estimation > div {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  flex: 0;
  padding: 0 20px;
}

.estimation > div > div {
  white-space: nowrap;
}

@media (max-width: 960px) {
  .estimation-subheader {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
}

@media (max-width: 560px) {
  .estimation {
    width: 100%;
    flex-flow: column nowrap !important;
    align-items: stretch;
  }

  .estimation > div {
    width: 100%;
    font-size: 1em;
    flex-flow: row-reverse nowrap;
    justify-content: space-between;
  }
}

.rct-icon-parent-close,
.rct-icon-parent-open {
  color: #1894db;
}

.rct-icon-leaf {
  color: #eb3b7f;
}

.card-employee-profile {
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgb(235, 235, 235);
  padding: 0 !important;
}

.bigCalendarView {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: calc(100vh - 215px);
  min-height: max-content;
  flex: 1 1;
}

.page-item.active .page-link {
  background-color: #06b5fd !important;
  border-color: #06b5fd !important;
}

.display-flex {
  display: inline-flex;
}

.min-height-200 .react-checkbox-tree {
  min-height: 221px;
}

.full-width
  .select-search-box.select-search-box--multiple.select-search-box--select {
  width: 100% !important;
}

.Collapsible__trigger:hover {
  cursor: pointer;
  color: #1894db;
}

.loader {
  z-index: 98 !important;
  position: fixed !important;
  top: 0;
  right: 0;
}

.modal-header span.date {
  font-size: inherit;
}

.row-color {
  background: #eaeef2;
}

.labor-summary-section {
  position: relative;
  transition: background-color 0.3s ease;
  padding: 10px 25px;
  text-align: center;
  float: left;
}

.premium-guard {
  opacity: 0.5;
  cursor: pointer;
}

.font-14 {
  font-size: 14px;
}

.weight-600 {
  font-weight: 600;
}

.text-color-gray {
  color: #738190;
}

.text-color-black {
  color: #000000;
}

.color-light-color {
  color: #403d3d;
}

.modal-body.custom-height {
  max-height: calc(100vh - 131px);
  overflow: auto;
}

.modal-body.custom-height.min-height-300 {
  min-height: 400px;
}

.shift.green {
  color: #3e8c42 !important;
  background-color: #e0f1e1 !important;
  border-color: #52b257 !important;
}

.shift.blue {
  color: #0b77cf !important;
  background-color: #d9edfd !important;
  border-color: #2497f3 !important;
}

.shift.red {
  color: #cd1613 !important;
  background-color: #fcdddc !important;
  border-color: #ec3633 !important;
}

.shift.gray {
  color: #707070 !important;
  background-color: #ececec !important;
  border-color: #909090 !important;
}

.shift.yellow {
  color: #d4ad02 !important;
  background-color: #fff7d7 !important;
  border-color: #fdd217 !important;
}

.shift.pink {
  background-color: #fbd9e4 !important;
  border-color: #e92567 !important;
  color: #bf134d !important;
}

.shift.purple {
  background-color: #fbd9e4 !important;
  border-color: #9b3eab !important;
  color: #793085 !important;
}

.shift.teal {
  background-color: #cdf4f0 !important;
  border-color: #22958b !important;
  color: #1b746c !important;
}

.shift.orange {
  background-color: #ffedd4 !important;
  border-color: #ff9b09 !important;
  color: #cd7a00 !important;
}

.shift.cyan {
  background-color: #d4f4f8 !important;
  border-color: #22b3c5 !important;
  color: #1a8b99 !important;
}

.shift.red-lines {
  color: #b10f0f !important;
  font-weight: 500 !important;
  border-color: #b10f0f !important;
  background-image: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0) 46%,
    #e03218 30%,
    #e03218 30%,
    rgba(0, 0, 0, 0) 52%
  ) !important;
  background-size: 6px 6px !important;
  background-color: transparent !important;
}

.shift.released {
  background-image: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0) 46%,
    #01baef60 30%,
    #01baef60 30%,
    rgba(0, 0, 0, 0) 52%
  ) !important;
  background-size: 6px 6px !important;
  background-color: transparent !important;
  border-color: #01baef !important;
  color: #0681ec !important;
}

.shift.green:hover {
  color: #fff !important;
  background-color: #66bb6a !important;
  border-color: #52b257 !important;
}

.shift.blue:hover {
  color: #fff !important;
  background-color: #42a5f5 !important;
  border-color: #2497f3 !important;
}

.shift.red:hover {
  color: #fff !important;
  background-color: #ef5350 !important;
  border-color: #ec3633 !important;
}

.shift.gray:hover {
  color: #fff !important;
  background-color: #a0a0a0 !important;
  border-color: #909090 !important;
}

.shift.yellow:hover {
  color: #fff !important;
  background-color: #fdd835 !important;
  border-color: #fdd217 !important;
}

.shift.pink:hover {
  color: #fff !important;
  background-color: #e92567 !important;
  border-color: #e92567 !important;
}

.shift.purple:hover {
  color: #fff !important;
  background-color: #ab47bc !important;
  border-color: #ab47bc !important;
}

.shift.teal:hover {
  color: #fff !important;
  background-color: #26a69a !important;
  border-color: #22958b !important;
}

.shift.orange:hover {
  color: #fff !important;
  background-color: #ffa726 !important;
  border-color: #ff9b09 !important;
}

.shift.cyan:hover {
  color: #fff !important;
  background-color: #26c6da !important;
  border-color: #22b3c5 !important;
}

.shift.released:hover {
  background-color: #01baef !important;
  background-image: none !important;
  color: #ffffff !important;
}

.shift.red-lines:hover {
  background-color: #e88f8f !important;
  background-image: none !important;
  color: white !important;
}

.shift.red-lines.hover {
  background-color: #e88f8f !important;
  background-image: none !important;
  color: white !important;
}

.min-width-380 {
  min-width: 380px;
}

.b-float-root > .b-floating.b-toast.toast-error {
  background-color: indianred;
}

.scheduler-collapse .Collapsible__contentInner {
  margin-left: 0;
}

.flex-1 {
  flex: 1;
}

.header-container button {
  flex: 1;
  white-space: nowrap;
  max-width: 130px;
}
.scheduler-collapse.forecast .Collapsible__contentOuter {
  overflow: unset !important;
}


/* Zones Colors */

/* Zone 1 */
/* .shift.color_zone_1 {
    color: #14288e!important;
    border-color: #5766b5!important;
    background-color: #c3c9ec!important;
}
.shift.color_zone_1:hover{
    background-color: #5766b5!important;
    color: white!important;
} */

/* Zone 2 */
/* .shift.color_zone_2 {
    color: #136e65!important;
    border-color: #1db4a5!important;
    background-color: #3ae4d3!important;
}
.shift.color_zone_2:hover{
    background-color: #1db4a5!important;
    color: white!important;
} */

/* Zone 3 */
/* .shift.color_zone_3 {
    color: #805834!important;
    border-color: #cf8e52!important;
    background-color: #f3bb86!important;
}
.shift.color_zone_3:hover{
    background-color: #cf8e52!important;
    color: white!important;
} */

/* Zone 4 */
/* .shift.color_zone_4 {
    color: #683fb6!important;
    border-color: #896cbd!important;
    background-color: #d6b0f5!important;
}
.shift.color_zone_4:hover{
    background-color: #896cbd!important;
    color: white!important;
} */

/* Zone 5 */
/* .shift.color_zone_5 {
    color: #3f3f3f!important;
    border-color: #9e8e84!important;
    background-color: #d3c4ba!important;
}
.shift.color_zone_5:hover{
    background-color: #9e8e84!important;
    color: white!important;
} */

/* Zone 6 */
/* .shift.color_zone_6 {
    color: #d14c40!important;
    border-color: #e7786e!important;
    background-color: #f3b9b4!important;
}
.shift.color_zone_6:hover{
    background-color: #e7786e!important;
    color: white!important;
} */

/* Zone 7 */
/* .shift.color_zone_7 {
    color: #013a20!important;
    border-color: #1fa568!important;
    background-color: #53e4a3!important;
}
.shift.color_zone_7:hover{
    background-color: #1fa568!important;
    color: white!important;
} */

/* Zone 8 */
/* .shift.color_zone_8 {
    color: #837a2c!important;
    border-color: #bbac29!important;
    background-color: #e4d873!important;
}
.shift.color_zone_8:hover{
    background-color: #bbac29!important;
    color: white!important;
} */

/* Zone 9 */
/* .shift.color_zone_9 {
    color: #4d664f!important;
    border-color: #8baf8f!important;
    background-color: #b2c7b4!important;
}
.shift.color_zone_9:hover{
    background-color: #8baf8f!important;
    color: white!important;
} */

/* Zone 10 */
/* .shift.color_zone_10 {
    color: #832950!important;
    border-color: #e980ae!important;
    background-color: #f7a6c9!important;
}
.shift.color_zone_10:hover{
    background-color: #e980ae!important;
    color: white!important;
} */

/* Zone 11 */
/* .shift.color_zone_11 {
    color: #353e74!important;
    border-color: #5a6cd1!important;
    background-color: #a8b4f7!important;
}
.shift.color_zone_11:hover{
    background-color: #5a6cd1!important;
    color: white!important;
} */

/* Zone 12 */
/* .shift.color_zone_12 {
    color: #6b673b!important;
    border-color: #ccc46d!important;
    background-color: #e7e3bb!important;
}
.shift.color_zone_12:hover{
    background-color: #ccc46d!important;
    color: white!important;
} */
.no-pointer {
  pointer-events: none !important;
}
.b-grid-row.no-pointer{
  background-color: #dc324130;
}