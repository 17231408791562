.b-react-monthly-container table {
  width: 100%;
  table-layout: fixed;
  background-color: transparent;
}
.b-react-monthly-container .month-tile.is-current-month {
  background: #f8f9fa;
}
.b-react-monthly-container .month-tile .month-tile-header.today {
  color: #0085ff;
}
.b-react-monthly-container .month-tile {
  box-sizing: border-box;
  background: #e2e6ea;
  border: 1px solid rgba(0,0,0,.08);
  border-top-width: 0;
  vertical-align: top;
  position: relative;
}
.b-react-monthly-container .month-tile .month-tile-header {
  color: #97a2ae;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 3px;
  margin-left: 2px;
  height: 14px;
}
.b-react-monthly-container .month-tile>div {
  padding: 4px;
  min-height: 115px;
}
.calendar-header-cell {
  -ms-flex: 2;
  flex: 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid rgba(0,0,0,.08);
  color: #97a2ae;
  height: 30px;
  font-size: 13px;
  font-weight: 600;
}
.calendar-header {
  display: -ms-flexbox;
  display: flex;
  max-width: 99.9%;
  background-color: #fafafa;
}
.header-border {
  border: 1px solid rgba(0,0,0,.08);
}
.calendar-header-cell:last-child{
  border-right: none;
}
.b-react-monthly-container .month-tile.today {
  background: hsla(0,0%,100%,.5);
}
.padding-top-8 {
  padding-top: 8px!important;
}
.b-react-monthly-container .month-tile .shift {
  height: auto;
  font-size: 11px;
  line-height: 14px;
  margin-bottom: 0;
}
.b-react-monthly-container .shift.is-past {
  opacity: .8;
}
.b-react-monthly-container .shift.published {
  border-style: solid;
}
.b-react-monthly-container .shift {
  min-height: 36px;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.2;
  border-radius: 2px;
  word-wrap: normal;
  white-space: nowrap;
  position: relative;
  text-overflow: ellipsis;
  color: #3e8c42;
  background-color: #e0f1e1;
  border-color: #52b257;
  border-left-width: 3px;
  border-left-style: solid;
  font-weight: 300;
}
.sFFAA35, .sFFAA35.position {
  background: #ffaa35;
  border-color: #ffaa35;
}
.b-react-monthly-container .shift-details {
  height: 100%;
  overflow: hidden;
  text-overflow: inherit;
  padding: 4px 6px;
  margin: -2px;
  border-radius: inherit;
  font-size: 11px;
  line-height: 1.3;
  font-weight: 600;
}

.b-react-monthly-container .month-tile .shift-details {
  text-overflow: ellipsis;
}

.b-react-monthly-container .month-tiles .shift-details,
.b-react-monthly-container .month-tiles .shift-details {
  padding: 2px 5px;
}
.position-relative {
  position: relative;
}
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.b-react-monthly-container .shift .duration {
  font-weight: 400;
}
