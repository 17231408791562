.overlay {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.7;
  transition: 0.5s ease;
  background-color: #484c4d;
  color: #ffffff;
  display: none;
}

.userphoto {
  position: relative !important;
  cursor: pointer;
}

.userphoto::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.68);
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: none;
  cursor: pointer;
}

.userphoto::before {
  content: "Choose image";
  word-wrap: break-word;
  max-width: 60px;
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: white;
  vertical-align: middle;
  line-height: 20px;
  font-weight: 900;
  /* text-transform: uppercase; */
  display: none;
  cursor: pointer;
  z-index: 1;
}

.userphoto:hover:after,
.userphoto:hover:before {
  display: block;
}
