.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.ExcelTable {
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
}

.ExcelTable td,
.ExcelTable th {
  border: 1px solid lightgray;
  padding: 5px 10px;
}

.ExcelTable th {
  background-color: #1894db;
  border-color: #1894db;
  color: white;
}

.table-data-container {
  max-height: 500px;
  overflow-y: auto;
}
.color-picker.color_zone_1 {
  background-color: #c3c9ec;
  border-color: #5766b5;
}
.color-picker.color_zone_1:hover {
  background-color: #5766b5;
}
.color-picker.color_zone_2 {
  background-color: #3ae4d3 ;
  border-color: #1db4a5;
}
.color-picker.color_zone_2:hover {
  background-color: #1db4a5;
}
.color-picker.color_zone_3 {
  background-color: #f3bb86 ;
  border-color: #cf8e52;
}
.color-picker.color_zone_3:hover {
  background-color: #cf8e52;
}
.color-picker.color_zone_4 {
  background-color: #d6b0f5 ;
  border-color: #896cbd;
}
.color-picker.color_zone_4:hover {
  background-color: #896cbd;
}
.color-picker.color_zone_5 {
  background-color: #d3c4ba ;
  border-color: #9e8e84;
}
.color-picker.color_zone_5:hover {
  background-color: #9e8e84;
}
.color-picker.color_zone_6 {
  background-color: #f3b9b4 ;
  border-color: #e7786e;
}
.color-picker.color_zone_6:hover {
  background-color: #e7786e;
}
.color-picker.color_zone_7 {
  background-color: #53e4a3 ;
  border-color: #1fa568;
}
.color-picker.color_zone_7:hover {
  background-color: #1fa568;
}
.color-picker.color_zone_8 {
  background-color: #e4d873 ;
  border-color: #bbac29;
}
.color-picker.color_zone_8:hover {
  background-color: #bbac29;
}
.color-picker.color_zone_9 {
  background-color: #b2c7b4 ;
  border-color: #8baf8f;
}
.color-picker.color_zone_9:hover {
  background-color: #8baf8f;
}
.color-picker.color_zone_10 {
  background-color: #f7a6c9 ;
  border-color: #e980ae;
}
.color-picker.color_zone_10:hover {
  background-color: #e980ae;
}
.color-picker.color_zone_11 {
  background-color: #a8b4f7 ;
  border-color: #5a6cd1;
}
.color-picker.color_zone_11:hover {
  background-color: #5a6cd1;
}
.color-picker.color_zone_12 {
  background-color: #a93636 ;
  border-color: #a93636;
}
.color-picker.color_zone_12:hover {
  background-color: #a93636;
}
.color-picker{
  height: 2em;
  width: 2em;
  color: white;
  border-radius: 2em;
  padding: 0; 
  margin-bottom: 5px;
  outline: none;
  border: none;
}
button.color-picker:focus{
  outline: none;
}
.color-picker:not(:last-child) {
  margin-right: 5px;
}
.color-container{
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
}
