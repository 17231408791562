.fileUploader {
  width: 100%;
}

.fileContainer {
  background: #fff;
  /* box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05); */
  position: relative;
  /* border-radius: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* margin: 10px auto; */
  transition: all 0.3s ease-in;
}

.fileContainer input {
  opacity: 0;
  width: 0;
  position: absolute;
  z-index: -1;
  right: 0;
}

.fileContainer p {
  font-size: 12px;
  margin: 8px 0 4px;
}

.fileContainer .errorsContainer {
  max-width: 300px;
  font-size: 12px;
  color: red;
  text-align: left;
}

.fileContainer .chooseFileButton {
  padding: 4px 15px;
  background: white;
  border-radius: 3px;
  color: rgb(83, 83, 83);
  /* font-weight: 300; */
  font-size: 14px;
  /* margin: 10px 0; */
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: 1px solid #c2c2c2;
}

.fileContainer .chooseFileButton:hover {
  background: #dddee2;
}

.fileContainer .uploadFilesButton {
  padding: 5px 43px;
  background: transparent;
  border-radius: 30px;
  color: #3f4257;
  font-weight: 300;
  font-size: 14px;
  margin: 10px 0;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: 1px solid #3f4257;
}

.fileContainer .uploadFilesButton:hover {
  background: #3f4257;
  color: #fff;
}

.fileContainer .uploadIcon {
  width: 50px;
  height: 50px;
}

.fileContainer .uploadPicturesWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.fileContainer .uploadPictureContainer {
  width: 25%;
  margin: 5%;
  padding: 10px;
  background: #edf2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0dbe4;
  position: relative;
}

.fileContainer .uploadPictureContainer img.uploadPicture {
  width: 100%;
}

.fileContainer .deleteImage {
  position: absolute;
  top: -9px;
  right: -9px;
  color: #fff;
  background: #ff4081;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  font-weight: bold;
  line-height: 30px;
  width: 30px;
  height: 30px;
}

.flipMove {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
