.clock-in-container {
  margin-top: 8rem;
}

@media screen and (max-width: 992px) {
  .clock-in-container {
    margin-top: 20px;
  }
}

.clock-in-container .user-avatar {
  max-width: 280px;
  max-height: 280px;
  width: 280px;
  height: 280px;
  background-color: #bec5ca;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f3f3f3;
  margin: 0;
  position: relative;
  border-radius: 0.5em;
  box-shadow: 0 15px 30px -15px rgba(14, 17, 32, 0.3);
}

@media screen and (max-width: 991px) {
  .clock-in-container .user-avatar {
    width: 200px;
    height: 200px;
  }
}
@media screen and (max-width: 767px) {
  .clock-in-container .user-avatar {
    width: 150px;
    height: 150px;
  }
}

.clock-in-container .info {
  font-size: 1.2em;
  color: #333;
  font-weight: 600;
  margin-bottom: 1em;
  margin-top: 0.5em;
}

.clock-in-container .time {
  font-size: 2em;
  font-weight: 700;
  margin: 1rem 0;
}

.clock-in-container .params {
  width: 80%;
}

.clock-in-container .params .form-group {
  width: 100%;
}

.clock-in-container .actions {
  width: 80%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.clock-in-container .actions > .btn {
  width: 100%;
  margin-bottom: 1em;
}

.clock-in-container .actions .btn {
  border-radius: 4rem;
}

.clock-in-container .actions .breaks .btn {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 0.5em;
}

.clock-in-container .actions .btn > i {
  margin-right: 0.7em;
}

.clock-in-container .tabs {
  display: flex;
  flex-flow: row nowrap;
}

.clock-in-container .tabs > .tab {
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 700;
  padding: 1em 2em;
  color: #666464;
  border-bottom: 3px solid #f3f3f3;
  transition: background-color 300ms, border-color 200ms;
  cursor: pointer;
}
.clock-in-container .tabs > .tab:hover {
  background-color: whitesmoke;
}
.clock-in-container .tabs > .tab.active {
  border-bottom-color: #1894db;
  color: #1894db;
}
.clock-in-container .tabs > .tab:disabled {
  background-color: whitesmoke;
  color: #bec5ca;
}

.clock-in-container .logs {
  display: flex;
  flex-flow: column nowrap;
  padding: 2rem 0;
  overflow-y: auto;
  max-height: 500px;
}
.clock-in-container .logs > div {
  margin-bottom: 0.5em;
  font-size: 1.1em;
}

.clock-in-container .logs .log-placeholder {
  font-size: 1.2em;
  color: gray;
}
