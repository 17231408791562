/* body {
    margin      : 2em;
    font-family : "Source Sans Pro", "Helvetica Neue", Helvetica, sans-serif;
    font-size   : 15px;
}

h1 {
    margin      : 0;
    font-size   : 1.5em;
    color       : #fff;
    font-weight : normal;
}

body > header {
    padding             : 1.5em 4em;
    background          : url(../../_shared/images/logo_bryntum_bw.png) #26C6DA;
    background-repeat   : no-repeat;
    background-size     : 30px;
    background-position : center left 1.5em;
} */
#tools {
    display       : flex;
    margin-bottom : 1em;
    align-items   : center;
}

#tools button {
    width         : 8em;
    padding       : .7em;
    background    : #66BB6A;
    border        : none;
    color         : #fff;
    cursor        : pointer;
    font-size     : 1em;
    font-family   : "Source Sans Pro", "Helvetica Neue", Helvetica, sans-serif;
    border-radius : 2px;
    margin-right  : .5em;
}

#tools button:hover {
    background : #81C784;
}

#tools button:active {
    background : #A5D6A7;
}

#tools button:disabled {
    background : #FF9269 !important;
}

#tools button.red {
    background : #EF5350;
}

#tools button.red:hover {
    background : #F67647;
}

#tools .selected-event {
    margin-left : 2em;
    flex        : 1;
}

#tools .selected-event span {
    font-size : 1.25em;
    position  : relative;
    top       : 1px;
    left      : .5em;
}

#tools .barmargin input {
    font-size     : 15px;
    font-family   : inherit;
    padding       : .7em;
    border-radius : 2px;
    border        : 1px solid #31AA73;
    margin-left   : .5em;
}

.b-scheduler {
    border              : 1px solid #e0e0e7;
    border-bottom-width : 0;
}

.b-scheduler .b-sch-range {
    background : repeating-linear-gradient(-55deg, #ddd, #ddd 10px, #eee 5px, #eee 20px);
    opacity    : 0.7;
}

.b-scheduler .b-widget,
.b-scheduler .b-grid-cell {
    font-weight : 300;
}

.b-watermark {
    display: none;
}

.b-float-root .b-panel-content #b-combo-10 {
    display: none;
}

/* b-has-label b-widget b-field b-textfield b-pickerfield b-combo b-not-editable b-resize-monitored */

.SingleDatePicker_picker {
    z-index: 100!important;
}

.DateRangePickerInput .DateInput_input {
    padding-top: 3px;
    padding-bottom: 0;
    font-size: 14px;
}

.overtime-event-info {
    display: block;
}

.overtime-event {
    color: #EF5350!important;
    padding-top: 25px;
    margin-left: 5px;
}

.b-grid-cell {
    padding-top: 25px!important;
}

.b-sch-style-plain {
    font-size: 12px!important;
    padding-left: 5px!important;
}

.schedule-select {
    max-height: 31px!important;
    height: 30px !important;
    padding: 5px 10px !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
}

.DateRangePicker_picker {
    z-index: 100!important;
}

.b-react-scheduler-container {
    background-color: #fff;
}
.b-sch-header-row.b-sch-header-row-top {
    font-weight: bold;
    color: #06b5fd;
}

.b-textfield.b-disabled label {
    color: #616161 !important;
}
.white-space-normal{
  white-space: normal;
}
.font-size,
.b-sch-header-timeaxis-cell,
.b-grid-header-text-content{
  font-size: 11px!important;
  font-family: Nunito, Arial, Helvetica, sans-serif;
}
.nested {
  position: absolute;
  background-color: inherit;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nested.break {
    position: absolute;
    background-image: repeating-linear-gradient(45deg, #ddd, #ddd 10px, #eee 10px, #eee 20px);
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 11px;
    color: #ef4a05!important;
}

/*.b-sch-event {
  padding-left: 0;
  background-image: repeating-linear-gradient(45deg, #ddd, #ddd 10px, #eee 10px, #eee 20px);
  border-radius: 5px;
}*/
.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red-lines .b-sch-event:not(.b-milestone) {
    color            : #b10f0f;
    font-weight      : 500;
}
.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red-lines .b-sch-event {
    border-color: #b10f0f;
    background-image : linear-gradient(-45deg, rgba(0, 0, 0, 0) 46%, #e03218 30%, #e03218 30%, rgba(0, 0, 0, 0) 52%);
    background-size  : 6px 6px !important;
    background-color: transparent;
}
.b-sch-style-colored.b-sch-event-wrap.b-sch-color-red-lines .b-sch-event:hover,
.b-sch-style-colored.b-sch-event-wrap.b-sch-color-red-lines .b-sch-event.b-sch-event-resizing,
.b-sch-style-colored.b-sch-event-wrap.b-sch-color-red-lines .b-sch-event.b-sch-event-selected {
    background-color: #e88f8f;
    background-image: none;
}
.b-sch-style-colored.b-sch-event-wrap.b-sch-color-red-lines .b-sch-event.b-sch-event-selected:hover{
    background-color: #e88f8f;
}

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray-lines .b-sch-event:not(.b-milestone) {
    color            : #707070;
    font-weight      : 500;
}
.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray-lines .b-sch-event {
    border-color: #909090;
    background-image : linear-gradient(-45deg, rgba(0, 0, 0, 0) 46%, #909090 30%, #909090 30%, rgba(0, 0, 0, 0) 52%);
    background-size  : 6px 6px !important;
    background-color: transparent;
}
.b-sch-style-colored.b-sch-event-wrap.b-sch-color-gray-lines .b-sch-event:hover,
.b-sch-style-colored.b-sch-event-wrap.b-sch-color-gray-lines .b-sch-event.b-sch-event-resizing,
.b-sch-style-colored.b-sch-event-wrap.b-sch-color-gray-lines .b-sch-event.b-sch-event-selected {
    background-color: #828282;
    background-image: none;
}
.b-sch-style-colored.b-sch-event-wrap.b-sch-color-gray-lines .b-sch-event.b-sch-event-selected:hover{
    background-color: #828282;
}

.b-sch-event:hover .nested.break{
    background-image: repeating-linear-gradient(45deg, #ccc, #ccc 10px, #ddd 10px, #ddd 20px) !important;
}
.b-sch-event:not(.b-milestone).b-sch-event-selected, .b-sch-event:not(.b-milestone).b-sch-event-selected .nested.break{
    color: #b10f0f!important;
}
.sch-hdr-startend span {
    flex: 1;
    padding: 0 1em;
    text-align: left;
}
.sch-hdr-startend span:last-child {
    text-align: right;
}


.b-grid-subgrid .closed {
    background : repeating-linear-gradient(-55deg, #ddd, #ddd 10px, #eee 5px, #eee 20px);
    opacity    : 0.7;
    color      : #926b36;
}
.b-grid-subgrid .closed label {
    background  : #fff;
    font-weight : 500;
}
.b-sch-event-wrap:not(.b-milestone-wrap).b-active .b-sch-event {
    outline: none!important;
    outline-offset: unset!important;
}

.b-sch-event-wrap.b-sch-style-colored.b-sch-color-released .b-sch-event.released {
    border-color: #01BAEF;
    color: #0681ec;
    background-image: linear-gradient(-45deg,transparent 46%,rgba(0, 144, 185, 0.85) 0,rgba(0, 144, 185, 0.85) 0,transparent 52%);
    background-size  : 6px 6px !important;
    background-color: transparent;
}
.b-sch-event:not(.b-milestone).released.b-sch-event-selected,
.b-sch-event:not(.b-milestone).released.b-sch-event-selected *{
    color: #0681ec;
}
.b-active .b-sch-event:not(.b-milestone).released.b-sch-event-selected,
.b-active .b-sch-event:not(.b-milestone).released.b-sch-event-selected * {
    color: #ffffff;
}
.b-sch-event-wrap.b-sch-style-colored.b-sch-color-released.b-active .b-sch-event.released,
.b-sch-style-colored.b-sch-event-wrap .b-sch-event.released:hover,
.b-sch-style-colored.b-sch-event-wrap .b-sch-event:not(.b-milestone).released:hover,
.b-sch-style-colored.b-sch-event-wrap .b-sch-event:not(.b-milestone).released:hover *,
.released.b-sch-event-selected{
    background-color: #01BAEF;
    background-image: none;
    color: #ffffff;
}
.b-tooltip-content.tip-invalid{
    background: #de2828d9;
}
.b-tooltip-content.tip-valid{
    background: rgba(2, 189, 33, 0.85);
}
.b-sch-tip-invalid.drag-invalid{
    color: white!important;
}
.b-sch-tip-valid.drag-valid{
    color: white !important;
}
.b-grid.b-autoheight{
  max-height: calc(100vh - 180px);
}
.b-resource-info{
  height: 45px;
  margin-top: -25px;
}
.b-resource-info dl {
  margin-top: 25px;
}
.b-resource-info>.resource-avatar-img.user-avatar {
  width: 2.4em;
  height: 2.4em;
  border-radius: 50%;
  margin-right: .6em;
  border: none;
}
.b-resource-info > .user-avatar > div {
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  transform: translateY(1px);
}

.b-sch-event-wrap.b-sch-style-colored .b-sch-event {
    border-width: 5px;
}

/* Zone Colors*/
/* zone 1 */
.b-sch-event-wrap.b-sch-style-colored .b-sch-event.color_zone_1 span.zone.color_zone_1{
    background-color: #5766b5;
}

/* zone 2 */
.b-sch-event-wrap.b-sch-style-colored .b-sch-event.color_zone_2 span.zone.color_zone_2{
    background-color: #1db4a5;
}

/* zone 3 */
.b-sch-event-wrap.b-sch-style-colored .b-sch-event.color_zone_3 span.zone.color_zone_3{
    background-color: #cf8e52;
}

/* zone 4 */
.b-sch-event-wrap.b-sch-style-colored .b-sch-event.color_zone_4 span.zone.color_zone_4{
    background-color: #896cbd;
}
/* zone 5 */
.b-sch-event-wrap.b-sch-style-colored .b-sch-event.color_zone_5 span.zone.color_zone_5{
    background-color: #9e8e84;
}

/* zone 6 */
.b-sch-event-wrap.b-sch-style-colored .b-sch-event.color_zone_6 span.zone.color_zone_6{
    background-color: #e7786e;
}

/* zone 7 */
.b-sch-event-wrap.b-sch-style-colored .b-sch-event.color_zone_7 span.zone.color_zone_7{
    background-color: #1fa568;
}

/* zone 8 */
.b-sch-event-wrap.b-sch-style-colored .b-sch-event.color_zone_8 span.zone.color_zone_8{
    background-color: #bbac29;
}

/* zone 9 */
.b-sch-event-wrap.b-sch-style-colored .b-sch-event.color_zone_9 span.zone.color_zone_9{
    background-color: #8baf8f;
}

/* zone 10 */
.b-sch-event-wrap.b-sch-style-colored .b-sch-event.color_zone_10 span.zone.color_zone_10{
    background-color: #e980ae;
}

/* zone 11 */
.b-sch-event-wrap.b-sch-style-colored .b-sch-event.color_zone_11 span.zone.color_zone_11{
    background-color: #5a6cd1;
}

/* zone 12 */
.b-sch-event-wrap.b-sch-style-colored .b-sch-event.color_zone_12 span.zone.color_zone_12{
    background-color: #a93636;
}
span.zone{
    font-weight: 500;
    color: white;
    border-radius: 1em;
    padding: 0px 4px;
    letter-spacing: .8px;
    font-size: .9em;
}
.b-panel-content.b-popup-content.b-eventeditor-content.b-content-element {
    height: 30em;
    overflow-y: auto;
    overflow-x: hidden;
}