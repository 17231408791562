@media print {
  @page { size: "4in 2.5in"}
  span.zone.color_zone_1{
    background-color: #5766b5;
  }
  span.zone.color_zone_2{
      background-color: #1db4a5;
  }
  span.zone.color_zone_3{
      background-color: #cf8e52;
  }
  span.zone.color_zone_4{
      background-color: #896cbd;
  }
  span.zone.color_zone_5{
      background-color: #9e8e84;
  }
  span.zone.color_zone_6{
      background-color: #e7786e;
  }
  span.zone.color_zone_7{
      color: #53e4a3;
  }
  span.zone.color_zone_8{
      background-color: #e4d873;
  }
  span.zone.color_zone_9{
      background-color: #8baf8f;
  }
  span.zone.color_zone_10{
      background-color: #e980ae;
  }
  span.zone.color_zone_11{
      background-color: #5a6cd1;
  }
  span.zone.color_zone_12{
      background-color: #ccc46d;
  }
  span.zone{
    font-weight: 500;
    color: white;
    border-radius: 1em;
    padding: 0px 4px;
    letter-spacing: .8px;
    font-size: .9em;
  }
}
