.login-section {
  margin-top: -60px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100vw;
  height: 100vh;
}

.login-section.picture-1 {
  background-image: url("../../../../general/assets/images/login-bg1.jpeg");
}

.login-section.picture-2 {
  background-image: url("../../../../general/assets/images/login-bg2.jpg");
}

.login-section.picture-3 {
  background-image: url("../../../../general/assets/images/login-bg3.jpeg");
}

.login-section > div {
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  height: 100vh;
  width: 20vw;
  right: 10vw;
  min-width: 380px;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
}

.login-section .instructions {
  font-size: 0.9em;
}

.login-section > div img {
  width: 60%;
  align-self: center;
  margin: 7vmin 0;
}

.login-section > div form {
  outline: none;
}

.login-section > div a {
  color: white;
}

@media (max-width: 572px) {
  .login-section > div {
    position: relative;
    min-width: 100%;
    right: 0;
  }
}
