.all-events-modal {
    background-color: white;
    box-shadow: 0 8px 20px -6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    position: fixed;
    z-index: 1000;
    display: flex;
    flex-flow: column nowrap;
}

.all-events-modal > .title {
    padding: 0.8em;
    font-weight: 700;
    font-size: 1.3em;
    margin-bottom: 0;
}

.all-events-modal > .events-list {
    flex: 1;
    overflow-y: scroll;
}

.all-events-modal .events-loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
}
