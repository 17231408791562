.messages-container {
  display: flex;
  flex-flow: row nowrap;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 5px;
  align-items: stretch;
  height: 70vh;
  overflow: hidden;
  margin-bottom: 50px;
}

.messages-container > a {
  display: none;
}

.messages-container > nav {
  height: 100%;
  display: flex;
  flex: 1;
  max-width: 350px;
  flex-flow: column nowrap;
  border-right: 1px solid lightgrey;
}

.messages-container > nav > form {
  flex: 1;
  max-height: 40px;
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px solid lightgrey;
}

.messages-container > nav > form > input {
  border: none;
  flex: 1;
  padding: 10px 15px;
  outline: none;
}

.messages-container > nav > form > button > span {
  display: none;
}

.messages-container > nav > form > button {
  background-color: white;
  border: none;
  font-size: 17px;
  padding: 0px 15px;
  color: #1894db;
  border-left: 1px solid lightgrey;
  background-color: #eef2f6;
}

.messages-container > nav > .messages-list {
  flex: 1;
  overflow-y: auto;
}

.messages-container > nav > .messages-list > a {
  display: flex;
  flex: 1;
  max-width: 100%;
  flex-flow: row nowrap;
  border-bottom: 1px solid lightgrey;
  text-decoration: none;
  padding: 15px;
  color: inherit;
}

.messages-container > nav > .messages-list > a:hover,
.messages-container > nav > .messages-list > a.chat-active {
  background-color: #eef2f6;
}

.messages-container > nav > .messages-list > a > .user-avatar {
  margin-right: 15px;
}

.messages-container > nav > .messages-list > a > .details {
  display: flex;
  flex: 1;
  max-width: calc(100% - 75px);
  align-items: flex-start;
  flex-flow: column nowrap;
}

.messages-container > nav > .messages-list > a > .details > * {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messages-container > nav > .messages-list > a > .details > h5 {
  flex: 1;
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: 700;
}

.messages-container > nav > .messages-list > a > .details > .body {
  margin-bottom: 5px;
}

.messages-container > nav > .messages-list > a > .details > small {
  color: grey;
}

.messages-container > .inbox {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
}

.messages-container > .inbox .direct-chat-text {
  background-color: #e8ecf0;
  color: inherit;
}

.right .direct-chat-text:after,
.right .direct-chat-text:before {
  border-left-color: #e8ecf0 !important;
}

.direct-chat-msg:not(.right) .direct-chat-text:before {
  border-right-color: #e8ecf0 !important;
}

.messages-container > .inbox > .chat-content > .message-loader-indicator {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}

.messages-container > .inbox > .chat-content {
  flex: 1;
  display: flex;
  flex-flow: column-reverse nowrap;
  padding: 0 10px;
}

.messages-container > .inbox > form {
  flex: 0;
  border-top: 1px solid lightgrey;
  position: relative;
}

.messages-container .emoji-picker {
  position: absolute;
  bottom: 7px;
  right: 17px;
  width: 35px !important;
}

.messages-container .emoji-picker > .emoji-trigger {
  color: gray;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
}

.messages-container .emoji-picker > .emoji-trigger:hover {
  background-color: rgb(233, 232, 232);
}

.messages-container .emoji-picker > .picker-container {
  position: absolute;
  right: 100%;
  bottom: 100%;
}

.messages-container > .inbox > form > div.input {
  width: 100%;
  height: 100px;
  position: relative;
}

.messages-container > .inbox > form > div.input textarea {
  border-radius: 0;
  border: none;
  outline: none;
  border-bottom: 1px solid lightgrey;
  padding: 10px;
  resize: none;
  width: 100%;
  min-height: 100px;
}

.messages-container > .inbox > form > div.actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.messages-container > .inbox > form > div.actions > button {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

@media (max-width: 1076px) {
  .messages-container {
    flex-flow: column nowrap;
    height: unset;
  }

  .messages-container > nav {
    flex: 0;
    /* max-height: 150px; */
    max-width: 100%;
    background-color: white;
    border-right: none;
    border-bottom: 1px solid lightgray;
  }

  .messages-container > nav > form > button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  .messages-container > nav > form > button > span {
    display: initial;
    font-size: 0.9em;
    margin-right: 10px;
    font-weight: 700;
  }

  .messages-container > nav > .messages-list {
    display: flex;
    flex-flow: row nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .messages-container > nav > .messages-list > a {
    flex: 1;
    max-width: 60%;
    border-bottom: none;
    border-right: 1px solid lightgray;
    padding: 10px 20px;
    align-items: center;
    font-size: 0.8em;
  }

  .messages-container > nav > .messages-list > a:last-child {
    border-right: none;
  }

  .messages-container > .inbox > .chat-content {
    flex: 1;
    max-height: 40vh;
    min-height: 40vh;
  }
}

@media (max-width: 767px) {
  .messages-container > nav > .messages-list > a {
    max-width: 100%;
    min-width: 80%;
  }

  .messages-container > .inbox > form > div {
    flex-flow: column nowrap;
    align-items: flex-start;
    padding-top: 5px;
  }

  .messages-container > .inbox > form > div > div {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: space-between;
  }
  .messages-container > .inbox > form > div > div > .fileUploader {
    flex: 1;
    margin-right: 10px;
    margin-top: 10px;
  }

  .messages-container
    > .inbox
    > form
    > div
    > div
    > .fileUploader
    > .fileContainer,
  .messages-container > .inbox > form > div > div > .fileUploader button {
    width: 100%;
  }

  .messages-container > .inbox > form > div > button {
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }
}
