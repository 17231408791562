.forecast-settings .row.titles {
  font-size: 1.1em;
  font-weight: 600;
}

.forecast-settings .row.titles > div {
  /* padding: 0 5px; */
}

.forecast-settings .row.definition {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 3px 0;
  position: relative;
}

.forecast-settings .row.definition > div {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  white-space: nowrap;
  padding-top: 5px;
  padding-bottom: 5px;
}

.forecast-settings .row.definition > div > input {
  padding: 3px 8px;
  width: 80px;
  border-radius: 4px;
  border: 1px solid lightgray;
  outline: none;
  margin: 0 5px;
  text-align: end;
}

.forecast-settings .row.definition > div.add-new {
  background-color: rgb(241, 241, 241);
  width: 96%;
  margin-left: 2%;
  height: 1px;
  padding: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  position: absolute;
  bottom: -1px;
  cursor: pointer;
  transition: background-color 200ms;
}

.forecast-settings .row.definition > .add-new:hover {
  height: 2px;
  background-color: #1894db;
}

.forecast-settings .row.definition > .add-new:hover::after {
  opacity: 1;
  transform: scale(1) translate(-100%, -50%);
}

.forecast-settings .row.definition > .add-new::after {
  opacity: 0;
  content: "+";
  color: #1894db;
  position: absolute;
  left: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #1894db;
  border-radius: 50%;
  top: 50%;
  transform: scale(0.9) translate(-100%, -50%);
  display: flex;
  background-color: #d7e6ee;
  align-items: center;
  justify-content: center;
  transition: transform 100ms, background-color 100ms;
}

.forecast-settings .row.definition > .add-new:active::after {
  background-color: #a8cfe6;
}

@media (max-width: 767px) {
  .forecast-settings .card-body {
    padding: 0;
    padding-bottom: 20px;
  }

  .forecast-settings .row.titles {
    display: none;
  }

  .forecast-settings .row.definition {
    padding: 0;
    padding-top: 5px;
    border: 1px solid lightgray;
    border-radius: 4px;
    margin: 0;
    margin-bottom: 15px;
    overflow: hidden;
  }

  .forecast-settings .row.definition > div.add-new {
    position: relative;
    height: 30px;
    width: 100%;
    margin-left: 0;
    background-color: transparent;
  }

  .forecast-settings .row.definition > .add-new:hover {
    height: 30px;
    background-color: transparent;
  }

  .forecast-settings .row.definition > .add-new::after {
    content: "+ Add";
    transform: scale(1) translate(0, -50%);
    top: 50%;
    opacity: 1;
    width: 100%;
    height: 100%;
    border-radius: 0;
    border-left: none;
    border-right: none;
    background-color: whitesmoke;
    border-top-color: lightgray;
  }

  .forecast-settings .row.definition > .add-new:hover::after {
    transform: scale(1) translate(0, -50%);
  }

  .forecast-settings .row.definition > div:not(.add-new) {
    padding: 0 5px;
    margin-bottom: 5px;
  }

  .forecast-settings .row.definition > div.range {
    padding-bottom: 5px;
    padding-left: 0;
    margin-bottom: 5px;
    border-bottom: 1px solid lightgray;
  }
}
