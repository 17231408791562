.b-react-monthly-container table {
  width: 100%;
  table-layout: fixed;
  background-color: transparent;
}
.b-react-monthly-container .month-tile.is-current-month {
  background: #f8f9fa;
}
.b-react-monthly-container .month-tile .month-tile-header.today {
  color: #0085ff;
}
.b-react-monthly-container .month-tile {
  box-sizing: border-box;
  background: #e2e6ea;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-top-width: 0;
  vertical-align: top;
  position: relative;
}
.b-react-monthly-container .month-tile .month-tile-header {
  color: #97a2ae;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 3px;
  margin-left: 2px;
  height: 14px;
}
.b-react-monthly-container .month-tile > div {
  padding: 4px;
  min-height: 115px;
}
.daily .calendar-header-cell {
  -ms-flex: initial;
  flex: initial;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  color: #97a2ae;
  height: 30px;
  font-size: 0.85vw;
  font-weight: 600;
}
.calendar-header {
  display: -ms-flexbox;
  display: flex;
  max-width: 99.9%;
  background-color: #fafafa;
}
.calendar-header-cell.full-name {
  width: 100px !important;
}
.calendar-header-cell.hours {
  width: calc((100% - 100px) / 24);
}
.header-border {
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.calendar-header-cell:last-child {
  border-right: none;
}
.b-react-monthly-container .month-tile.today {
  background: hsla(0, 0%, 100%, 0.5);
}
.padding-top-8 {
  padding-top: 8px !important;
}
.b-react-monthly-container .month-tile .shift {
  height: auto;
  font-size: 11px;
  line-height: 14px;
  margin-bottom: 0;
}
.b-react-monthly-container .shift.is-past {
  opacity: 0.8;
}
.b-react-monthly-container .shift.published {
  border-style: solid;
}
.daily .b-react-monthly-container .shift {
  min-height: 36px;
  cursor: pointer;
  font-size: 0.8vw;
  line-height: initial;
  border-radius: 2px;
  word-wrap: normal;
  white-space: nowrap;
  position: relative;
  text-overflow: ellipsis;
  color: #3e8c42;
  background-color: #e0f1e1;
  border-color: #52b257;
  border-left-width: 3px;
  border-left-style: solid;
  font-weight: 300;
}
.sFFAA35,
.sFFAA35.position {
  background: #ffaa35;
  border-color: #ffaa35;
}
.daily .b-react-monthly-container .shift-details {
  height: 100%;
  overflow: hidden;
  text-overflow: inherit;
  padding: 2px 5px;
  margin: -2px;
  border-radius: inherit;
  font-size: 5.5px;
  line-height: initial;
  font-weight: 600;
}

.daily .b-react-monthly-container .month-tile .shift-details {
  text-overflow: ellipsis;
}

.daily .b-react-monthly-container .month-tiles .shift-details,
.daily .b-react-monthly-container .month-tiles .shift-details {
  padding: 2px 5px;
}
.daily .position-relative {
  position: relative;
}
.daily .ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.daily .b-react-monthly-container .shift .duration {
  font-weight: 400;
}

.daily .resource-row {
  display: flex;
  max-width: 99.9%;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  height: 45px;
}
.daily .cell {
  display: flex;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  color: #97a2ae;
  font-size: 0.85vw;
  font-weight: 600;
  flex: initial;
  width: 100%;
  bottom: 0;
  top: 0;
}
.daily .cell.full-name {
  left: 0;
  background-color: #ffffffb7;
  color: #000;
  word-break: break-word;
  padding: 5px;
}
.event {
  height: 36px;
  position: absolute;
  top: 5px;
}
.black-white.daily .shift .shift-details {
  border: 1px solid black !important;
}
.black-white.daily .shift {
  border: none;
}
.black-white.daily * {
  border-color: #999ea3 !important;
}
.daily .shift-details {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.daily .nbsp-if-empty {
  white-space: pre-wrap;
}
.daily .shift-details {
  border: 1px solid;
  border-left: none;
}
.hours-container {
  width: calc(100% - 100px);
  display: flex;
}
.hours-container span {
  width: 4.166666667% !important;
}
.cell.full-name {
  position: relative;
}
.resource-row .hours-container {
  position: relative;
}
.daily .b-react-monthly-container .break .shift-details {
  padding: 0 !important;
  margin: 1 !important;
  background-image: repeating-linear-gradient(
    45deg,
    #ddd,
    #ddd 10px,
    #eee 10px,
    #eee 20px
  );
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 5px;
  color: #ef4a05;
  border-left: 1px solid;
}
.black-white.daily .b-react-monthly-container .break .shift-details {
  padding: 0 !important;
  margin: 1 !important;
  background-image: none;
  color: #000;
}

.daily .nested {
  position: absolute;
  height: 100%;
}
