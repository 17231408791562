.modal-body {
    min-width: 40vw;
    display: flex;
    flex-flow: column nowrap;
}

input[type="checkbox"] {
    margin-right: 1em;
}

.form-check * {
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.top {
    margin-bottom: 1em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.top > h6 {
    margin-bottom: 0;
}
