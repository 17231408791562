.react-datepicker-wrapper input {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  transition: border-color 300ms;
}

.react-datepicker-wrapper input:focus {
  border-color: #1894db;
}

.select-filters {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 10px;
}

@media (max-width: 526px) {
  .select-filters {
    flex-flow: column nowrap;
    padding: 0 20px;
  }

  .select-filters > select {
    margin-bottom: 10px;
  }

  .e-panel.card .card-header h4 {
    font-size: 1rem;
  }
  .e-panel.card .card-body {
    padding-left: 0;
    padding-right: 0;
  }
  .section-body .e-panel.card .card-body {
    padding-top: 0;
  }
}
.min-height-300 .select-search-box__select.select-search-box__select--display {
  max-height: 220px !important;
}
.error-border,
.error-border div{
  border-color: red!important;
}
.row.p-3.bg-gray.border-white {
  margin: 0 0 10px 0;
}